import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { NavController, AlertOptions } from '@ionic/angular';
import { AlertService } from '../../../../global/services/alert.service';
import { Material } from '../../model/material';
import { MaterialType } from '../../model/material-type.model';
import { LocalStorageConstants } from 'src/app/global/model/local-storage.constants';
import { SpinnerDialog } from '@awesome-cordova-plugins/spinner-dialog/ngx';
import { Dialogs } from '@awesome-cordova-plugins/dialogs/ngx';
import { IncomingGoodsService } from 'src/app/features/incoming-goods/services/incoming-goods.service';
import { ZebraPrinterService } from '../../services/zebra-printer.service';

@Component({
  selector: 'app-label-list',
  templateUrl: './label-list.page.html',
  styleUrls: ['./label-list.page.scss'],
})
export class LabelListPage {

  public materialList: Material[] = []
  private materialType: MaterialType
  public printLabelForOrder: boolean = false;
  public labelHeaders = ["Supplier", "Material", "Batch-No.", "Roll/Bucket"];
  public labelValues = [];
  public rollBucketArray: any[] = [];
  private materialIds: any[] = [];
  private palletBuffer: number;
  private address: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private navCtrl: NavController,
    private spinnerDialog: SpinnerDialog,
    private dialogs: Dialogs,
    private incomingGoodsService: IncomingGoodsService,
    private alertService: AlertService,
    private zebraPrinterService: ZebraPrinterService
  ) {

    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.palletBuffer = this.router.getCurrentNavigation().extras.state.palletBuffer;
        console.log("label list page pallet buffer", this.palletBuffer);
        this.materialList = this.router.getCurrentNavigation().extras.state.products;
        this.materialType = this.router.getCurrentNavigation().extras.state.materialType;


        this.printLabelForOrder = this.router.getCurrentNavigation().extras.state.printLabelForOrder;
        console.log(" this.materialList print label ", this.materialList);

        this.materialList.forEach((product) => {
          console.log("productt print label ", product);

          console.log(" this.materialType print label ", this.materialType);
          if (this.printLabelForOrder === true) {
            let productLabelValues = [
              this.materialType.supplier,
              this.materialType.partNumber,
              product.batchOrLotNumber,
              product.rollOrBucketNumber,
              product.workArea
            ]
            this.labelValues.push(productLabelValues)
            this.labelHeaders.push('Work Area')

          } else {
            let productLabelValues = [
              this.materialType.supplier,
              this.materialType.partNumber,
              product.batchOrLotNumber,
              product.rollOrBucketNumber
            ]
            this.labelValues.push(productLabelValues)
          }
          this.rollBucketArray.push(product.rollOrBucketNumber)
        })

        this.rollBucketArray.shift();
      }
    });
  }

  async printLabels() {
    let zplCode = ""


    this.address = await this.zebraPrinterService.findPrinter();
    this.zebraPrinterService.print(this.address, '^XA' + zplCode + '^XZ');

    const printerAlertOptions: AlertOptions = {
      header: 'Printer',
      message: 'Feature currently disabled',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => { }
        }
      ]
    };
    await this.alertService.showAlert(printerAlertOptions);
  }

  async goBack() {
    try {
      debugger;
      this.spinnerDialog.show(null, this.translate.instant('LOADER_FINISH_PALLET'), true);
      this.materialIds = JSON.parse(localStorage.getItem(LocalStorageConstants.PRODUCT_IDS));
      console.log("finish pallet data ", this.materialIds, "pallate", this.palletBuffer);
      await this.incomingGoodsService.finishPallet(this.materialIds, this.palletBuffer);
      localStorage.setItem(LocalStorageConstants.PRODUCT_IDS, null);
      this.dialogs.alert(this.translate.instant('PALLET_FINISHED_SUCCESSFULLY'), "Tracey")
        .then(() => {
          this.navCtrl.navigateBack('/dashboard')
        })
    } catch (error) {
      localStorage.setItem(LocalStorageConstants.PRODUCT_IDS, null);
      throw new Error(error.message);
    } finally {
      this.spinnerDialog.hide()
    }

  }

  async nextItem() {
    try {
      let navigationExtras: NavigationExtras = {
        state: {
          reset: true,
          palletBuffer: this.palletBuffer,
        }
      }
      await this.navCtrl.navigateBack('/incoming-goods', navigationExtras);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      this.spinnerDialog.hide()
    }
  }

  async finishPalletClick() {
    try {
      this.spinnerDialog.show(null, this.translate.instant('LOADER_FINISH_PALLET'), true);
      this.materialIds = JSON.parse(localStorage.getItem(LocalStorageConstants.PRODUCT_IDS));
      let palletBuffer = this.palletBuffer;
      console.log("finish pallet data ", this.materialIds, "pallate", palletBuffer);
      await this.incomingGoodsService.finishPallet(this.materialIds, palletBuffer);
      localStorage.setItem(LocalStorageConstants.PRODUCT_IDS, null);
      this.dialogs.alert(this.translate.instant('PALLET_FINISHED_SUCCESSFULLY'), "Tracey")
        .then(() => {
          this.navCtrl.navigateBack('/dashboard')
        })
    } catch (error) {
      throw new Error(error.message);
    } finally {
      this.spinnerDialog.hide()
    }
  }

  checkDeliveyNote() {
    this.navCtrl.navigateForward('/incoming-goods/delivery-note');
  }

  async finishOrder() {
    this.navCtrl.navigateBack('/dashboard')
  }

  async nextOrder( ) {
    try {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          reset: true
        }
      }
      await this.navCtrl.navigateBack('/order-overview', navigationExtras);
    }
    catch (error) {
      throw new Error(error.message);
    } finally {
      this.spinnerDialog.hide();
    }
  }
}
