import { ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ThemeSwitcherService } from './global/services/theme-switcher.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateOptionService } from './global/services/translate-option.service';
import { ToastService } from './global/services/toast.service';
import { PrinterService } from '@pagmf/printer';
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx';
import { Dialogs } from '@awesome-cordova-plugins/dialogs/ngx';
import { Toast } from '@awesome-cordova-plugins/toast/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { SpinnerDialog } from '@awesome-cordova-plugins/spinner-dialog/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { SharedModule } from './global/shared.module';
import * as Parse from 'parse';
import { ParseAuthenticationService, ParseAuthorizationService, PermissionRoleResource } from '@pagmf/security';
import { ParseBuilder, ParseService } from '@pagmf/parse';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './features/auth/guards/auth.guard';
import { ConfigResource, FeatureResource, ProductResource } from '@pagtracey/resources';
import { VersionService } from './global/services/version.service';
import { NgIdleModule } from '@ng-idle/core';
import { SessionService } from './global/services/session.service';
import { GlobalErrorHandler } from './global/error-handler/global-error-handler';
import { Broadcaster } from '@awesome-cordova-plugins/broadcaster/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Printer } from '@awesome-cordova-plugins/printer/ngx';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const myService: ParseService = new ParseBuilder(Parse)
  .setApplicationId(environment.parseAppID)
  .setServerUrl(environment.parseServerUrl)
  .build();

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    NgIdleModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    IonicStorageModule.forRoot(),
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    { provide: ParseService, useValue: myService },
    { provide: 'AuthenticationService', useClass: ParseAuthenticationService, deps: [ParseService] },
    { provide: 'AuthorizationService', useClass: ParseAuthorizationService, deps: ['AuthenticationService'] },
    { provide: FeatureResource, useClass: FeatureResource, deps: [ParseService] },
    { provide: ProductResource, useClass: ProductResource, deps: [ParseService] },
    { provide: ConfigResource, useClass: ConfigResource, deps: [ParseService] },
    { provide: PermissionRoleResource, useClass: PermissionRoleResource, deps: [ParseService] },
    StatusBar,
    SplashScreen,
    Printer,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ThemeSwitcherService,
    TranslateOptionService,
    ToastService,
    Dialogs,
    Toast,
    AppVersion,
    SpinnerDialog,
    BluetoothSerial,
    AuthGuard,
    VersionService,
    SessionService,
    Broadcaster,
    { provide: PrinterService, useClass: PrinterService, deps: [BluetoothSerial] },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }