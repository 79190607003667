import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MaterialType } from '../model/material-type.model';

@Injectable()
export class CommonService{

  selectedSupplierSubject = new BehaviorSubject<string>(null)
  selectedMaterialTypeSubject = new BehaviorSubject<MaterialType>(null)

  constructor(  ){
    
  }

}