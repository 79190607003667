import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appendBoxUnit'
})
export class AppendBoxUnitPipe implements PipeTransform {

  transform(boxB: any, isBoxAvailable:boolean): any {
    try{
      if(isBoxAvailable === true){
           boxB = 'B';
      }else{
           boxB = null;
      }
    }
    catch(exception){
           console.error('error in append box unit:', exception);
    }
    return boxB;
  }

}
