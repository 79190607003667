import { Component } from '@angular/core';
import { MaterialType } from '../../model/material-type.model';
import { MaterialTypeService } from '../../services/material-type.service';
import { CommonService } from '../../services/common.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-material-list',
  templateUrl: './material-list.page.html',
})
export class MaterialListPage {

  originalList: MaterialType[] = []
  filteredList: MaterialType[] = []
  searchedString = ''

  constructor(private materialTypeService: MaterialTypeService,
    private commonService: CommonService,
    private navCtrl: NavController) {

  }

  async ionViewWillEnter() {
    let selectedSupplier = this.commonService.selectedSupplierSubject.value;
    if (selectedSupplier) {
      this.originalList = await this.materialTypeService.getMaterialTypesForSupplier(selectedSupplier);
    } else {
      this.originalList = await this.materialTypeService.getLocalMaterialList();
    }
    this.filteredList = this.originalList
  }

  async searchValueChanged(event: CustomEvent) {

    this.searchedString = event.detail.value
    this.filteredList = this.originalList.filter((materialType: MaterialType) => {
      let materialName = materialType.partNumber
      return materialName.toLocaleLowerCase().includes(this.searchedString.toLocaleLowerCase())
    })

  }

  materialSelected(materialType: MaterialType) {
    this.navCtrl.pop();
    this.commonService.selectedMaterialTypeSubject.next(materialType)
  }

}
