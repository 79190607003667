import { Injectable } from "@angular/core";
import { QueryOptions, ParseHttpResponseList, FilterFactory } from '@pagmf/parse';
import { FeatureResource, Feature, ParseFeature, FeatureCategory } from '@pagtracey/resources';

@Injectable()
export class FeatureService {
    constructor(private featureResource: FeatureResource) { }

    async getAll(): Promise<Feature[]> {
        const filterOptions: QueryOptions = this.featureResource.createQueryOptions();
        const response: ParseHttpResponseList<Feature> = await this.featureResource.getAll(filterOptions);
        return response.data || [];
    }

    async getAllForCategory(category: FeatureCategory): Promise<Feature[]> {
        const filterOptions: QueryOptions = this.featureResource.createQueryOptions();
        filterOptions.filters.push(FilterFactory.createEqualToFilter(ParseFeature.CATEGORY, category));
        filterOptions.sortBy = ParseFeature.ORDER;

        const response: ParseHttpResponseList<Feature> = await this.featureResource.getAll(filterOptions);
        return response.data || [];
    }
}