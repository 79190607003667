<ion-app>
  <ion-menu side="end" menuId="first" type="overlay" content-id="main">
    <ion-content class="app-menu-content">
      <div id="menu">
        <div class="inner">
          <ion-row class="ion-no-padding">
            <ion-col size="3" class="ion-no-padding">
              <ion-icon name="person-circle-outline" class="userIcon"></ion-icon>
            </ion-col>
            <ion-col size="9" class="ion-no-padding">
              <div class="margin_top_14" *ngIf="sideBarService.showLogout">
                <div class="font_size_16">{{username}}</div>
                <div class="font_size_14">{{userrole}}</div>
              </div>
            </ion-col>
          </ion-row>
          <h2>{{"MENU" | translate}}</h2>
          <ul>
            <li>
              <p (click)="sideBarService.languageSwitchOption()">{{"LANGUAGE" | translate}}</p>
            </li>
            <li>
              <p (click)="sideBarService.switchThemeOption()">{{"SELECT_THEME" | translate}}</p>
            </li>
            <li>
              <p (click)="sideBarService.changeAppMode()">{{ "APP_MODE" | translate }}</p>
            </li>
<!--            <li>-->
<!--              <p (click)="sideBarService.showExplantion()">{{"EXPLANATION" | translate}}</p>-->
<!--            </li>-->
            <li *ngIf="sideBarService.showLogout">
              <p (click)="sideBarService.logoutOption()">{{"LOGOUT" | translate}}</p>
            </li>
          </ul>
        </div>
      </div>
      <ion-grid class="ion-no-padding" class="verison-name">
        <ion-row ><app-version/></ion-row>
        <ion-row >{{appMode}}</ion-row>
      </ion-grid>
    </ion-content>
  </ion-menu>
  <ion-content>
    <ion-router-outlet id="main" [swipeGesture]="false"></ion-router-outlet>
  </ion-content>
</ion-app>
