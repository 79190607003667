import { Inject, Injectable } from "@angular/core";
import { Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { ConfigKey, ConfigResource, ParseConfig } from '@pagtracey/resources';
import { FilterFactory } from '@pagmf/parse';
import { AuthenticationService } from '@pagmf/security';
import { AlertOptions, NavController } from '@ionic/angular';
import { AlertService } from './alert.service';

@Injectable()
export class SessionService {

  private timerSubscription: Subscription;
  private authStateSubscription: Subscription;
  public authenticationState: Subject<boolean> = new Subject<boolean>();

  constructor(
    public translate: TranslateService,
    private idle: Idle,
    private navCtrl: NavController,
    @Inject('AuthenticationService') private authenticationService: AuthenticationService,
    private configResource: ConfigResource,
    private alertService: AlertService) {
    this.authStateSubscription = this.authenticationState.subscribe((authenticated: boolean) => {
      if (authenticated) {
        this.startTimer();
      } else {
        this.stopSubscription(this.timerSubscription);
      }
    });
    idle.setIdle(1);
    this.getTimeout().then((timeout) => {
      idle.setTimeout(timeout || 5 * 60);
    });
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  }

  public startTimer(): void {
    if (!this.authenticationService.isAuthenticated()) {
      return;
    }
    this.stopSubscription(this.timerSubscription);

    this.timerSubscription = this.idle.onTimeout.subscribe(() => {
      this.onSessionExpired();
    });
    this.idle.watch();
  }

  public async onSessionExpired(): Promise<void> {
    await this.authenticationService.logout();
    await this.navCtrl.navigateBack('/landing');
    const alertHeader = this.translate.instant('TIMEOUT.HEADER');
    const alertMessage = this.translate.instant('TIMEOUT.MESSAGE');
    const okButton = this.translate.instant('CONFIRM');

    const alertOptions: AlertOptions = {
      header: alertHeader,
      message: alertMessage,
      buttons: [{
        text: okButton,
        role: 'cancel',
        handler: () => { }
      }]
    };
    await this.alertService.showAlert(alertOptions);
  }

  private stopSubscription(subscription: Subscription): void {
    if (subscription) {
      subscription.unsubscribe();
    }
  }

  private async getTimeout(): Promise<number> {
    let timeout: number;
    try {
      const options = this.configResource.createQueryOptions();
      options.filters.push(FilterFactory.createEqualToFilter(ParseConfig.KEY_FIELD, ConfigKey.SESSION_TIMEOUT_MOBILE));
      const configResponse = await this.configResource.getFirst(options);
      if (!configResponse.error) {
        timeout = (+configResponse.data.value) * 60;
      }
    } catch (error) { }
    return timeout;
  }

  ngOnDestroy(): void {
    this.stopSubscription(this.timerSubscription);
    this.stopSubscription(this.authStateSubscription);
  }
}
