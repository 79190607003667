import { Component, OnInit, Input } from '@angular/core';
import bwipjs from 'bwip-angular2';

@Component({
  selector: 'app-pag-label',
  templateUrl: './pag-label.component.html',
  styleUrls: ['./pag-label.component.scss'],
})
export class PagLabelComponent implements OnInit {

  @Input('barcodeId')
  barcodeId : string;

  @Input('headers')
  headers : string[]

  @Input('values')
  values : string[]

  @Input('bucketArray')
  bucketArray : string[]

  imgSrc : string
  numbers : number[] = []  
  constructor() { }

  ngOnInit() {
    // this.viewId = this.barcodeId
    this.numbers = Array.from(Array(this.headers.length),(x,i)=>i)   
    let canvas = document.createElement('canvas');
    bwipjs( canvas, {
      bcid: 'qrcode',        // Barcode type
      text: this.barcodeId,  	  // Text to encode
      scale: 1,               // 3x scaling factor
      height: 20,             // Bar height, in millimeters
      width: 20,     // Always good to set this
    }, (err, cvs) => {
      if (err) {
        document.getElementById('err').innerText = 'Error occured. See browser log for more information';
        console.log(err);
      } else {
        this.imgSrc = canvas.toDataURL('image/png');
      }
    });
  }

}
