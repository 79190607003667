import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'nav-back-button',
  templateUrl: './back-button.component.html',
})
export class BackButtonComponent implements OnInit {

  constructor( private navCtrl : NavController ) { }

  ngOnInit() {}

  goBack () {
    this.navCtrl.back();
  }

}
