import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './features/auth/guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  { path: 'dashboard', loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },
  { path: 'landing', loadChildren: () => import('./features/landing/landing.module').then(m => m.LandingModule) },
  { path: 'login', loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule) },
  {
    path: 'order-material', loadChildren: () => import('./features/order-material/order-material.module').then(m => m.OrderMaterialModule)
  },
  { path: 'incoming-goods', loadChildren: () => import('./features/incoming-goods/incoming-goods.module').then(m => m.IncomingGoodsModule) },
  { path: 'outsource-material', loadChildren: () => import('./features/outsource-material/outsource-material.module').then(m => m.OutsourceMaterialPageModule) },
  { path: 'store-material', loadChildren: () => import('./features/store-material/store-material.module').then(m => m.StoreMaterialModule) },
  { path: 'relocate-material', loadChildren: () => import('./features/relocate-material/relocate-material.module').then(m => m.RelocateMaterialModule) },
  { path: 'order-overview', loadChildren: () => import('./features/order-overview/order-overview.module').then(m => m.OrderOverviewModule) },
  { path: 'internal-store-material', loadChildren: () => import('./features/internal-store-material/internal-store-material.module').then(m => m.InternalStoreMaterialPageModule) },
  { path: 'internal-outsource-material', loadChildren: () => import('./features/internal-outsource-material/internal-outsource-material.module').then(m => m.InternalOutsourceMaterialPageModule) },
  { path: 'show-material-info', loadChildren: () => import('./features/show-material-info/show-material-info.module').then(m => m.ShowMaterialInfoPageModule) },
  { path: 'split-material', loadChildren: () => import('./features/split-material/split-material.module').then(m => m.SplitMaterialModule) },
  { path: 'block-unblock', loadChildren: () => import('./features/block-unblock-material/block-unblock.module').then(m => m.BlockUnblockPageModule) },
  { path: 'app-mode-scanner', loadChildren: () => import('./global/pages/app-mode-scanner/app-mode-scanner.module').then(m => m.AppModeScannerPageModule) }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
