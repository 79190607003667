import { Injectable } from "@angular/core";
import { TraceyParseService } from 'src/app/global/services/tracey-parse.service';
import { ResponseModel } from 'src/app/global/model/response.model';
import { MaterialType } from '../model/material-type.model';
import { ServerConstants } from 'src/app/global/model/server.constants';
import { Storage } from '@ionic/storage';


@Injectable({ providedIn : 'root' })
export class MaterialTypeService{

  constructor( private parseService : TraceyParseService, private storage: Storage  ){

  }

  async fetchMaterialStock() : Promise<MaterialType[]> {

    let result : ResponseModel<MaterialType[]> = await this.parseService.executeFunction( 'fetchMaterialStock' , {}, true)
    if ( result.statuscode == ServerConstants.SUCCESS ){
      console.log("materials stock",result.data );
        return result.data
    } else {
        throw new Error(result.message)
    }

  }

  async storeDataLocally() {

    try{
      let result : MaterialType[] = await this.fetchMaterialStock();
      result.forEach(async (materialType) => {
        await this.storage.set( materialType.identNumber , materialType )
      });
    }
    catch( error ){
    }

  }

  async getLocalMaterialList() {
    let materiaTypeList = []
    await this.storage.forEach( (value, key, interationNumber) => {
      if( value[ 'partNumber' ] ){
        materiaTypeList.push( value );
      }
    } )
    materiaTypeList.sort( (a,b) => (a.partNumber.toLowerCase() > b.partNumber.toLowerCase()) ? 1 : -1  )
    return materiaTypeList as [MaterialType];
  }

  async getMaterialTypeLocally ( identNumber : string )  {
    let result = await this.storage.get( identNumber );
    return result
  }

  async getMaterialTypesForSupplier(supplier: string): Promise<MaterialType[]> {
    const materialTypeList: MaterialType[] = [];
    await this.storage.forEach((value: MaterialType) => {
      if (value.supplier?.startsWith(supplier.toUpperCase())) {
        if (value.labelTemplate) {
          value.labelTemplate = JSON.parse((value.labelTemplate as unknown as string).replace(/\\/g, '\\\\'));
        }
        materialTypeList.push(value);
      }
    });
    return materialTypeList;
  }

  async getMaterialByPartNumber( partNumber : string ) : Promise<MaterialType> {
    let materiaTypeList = []
    await this.storage.forEach( (value, key, interationNumber) => {
      if( value.partNumber == partNumber ){
        materiaTypeList.push( value );
      }
    } )
    return materiaTypeList[0] as MaterialType;
  }

  async getMaterialByIdentNumber( identNumber : string ) : Promise<MaterialType> {
    let materialType = await this.storage.get( identNumber );
    if( materialType == null ){
      throw new Error('MATERIAL_TYPE_NOT_FOUND')
    }
    return materialType as MaterialType;
  }

  async getMaterialBySupplierIdentNumber( supplierIdentNumber : string ) : Promise<MaterialType> {
    let materiaTypeList = []
    await this.storage.forEach( (value, key, interationNumber) => {
      if( value.supplierIdentNumber == supplierIdentNumber ){
        materiaTypeList.push( value );
      }
    } )
    return materiaTypeList[0] as MaterialType;
  }

}
