import { Component, Inject } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { SideBarService } from './global/services/side-bar.service';
import { LocalStorageConstants } from './global/model/local-storage.constants';
import { VersionService } from './global/services/version.service';
import { SessionService } from './global/services/session.service';
import { Broadcaster } from '@awesome-cordova-plugins/broadcaster/ngx';
import { AndroidIntent } from './global/model/android-intent.constants';
import { AuthenticationService } from '@pagmf/security';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  appMode: string = "Production Mode";
  username: string = "Username";
  userrole: string = "Role";

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public sideBarService: SideBarService,
    private versionService: VersionService,
    private sessionService: SessionService,
    private broadCaster: Broadcaster,
    @Inject('AuthenticationService') private authenticationService: AuthenticationService,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {

      let appMode = localStorage.getItem(LocalStorageConstants.APP_MODE)
      this.appMode = appMode + " Mode"

      this.sideBarService.updateUserInfo.subscribe(() => {
        this.username = localStorage.getItem(LocalStorageConstants.USER_NAME)
        this.userrole = localStorage.getItem(LocalStorageConstants.ROLE)
      });

      if (this.platform.is('mobile')) {
        this.addIntentSubscriber()
      }

      this.username = localStorage.getItem(LocalStorageConstants.USER_NAME)
      this.userrole = localStorage.getItem(LocalStorageConstants.ROLE)
      this.statusBar.styleLightContent();
      this.splashScreen.hide();
      this.versionService.checkIfUpdated();
    });
  }

  private addIntentSubscriber() {

    this.broadCaster.addEventListener(AndroidIntent.SCREEN_OFF, true).subscribe(() => {
      if (this.authenticationService.isAuthenticated()) {
        this.sessionService.onSessionExpired();
      }
    });

    this.platform.pause.subscribe(() => {
      //console.log('APP was suspended!');
    });
  }

}
