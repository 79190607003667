import { Injectable } from "@angular/core";
import * as Parse from 'parse';
import { ResponseModel } from '../model/response.model';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageConstants } from '../model/local-storage.constants';

@Injectable()
export class TraceyParseService {

    constructor(private translate: TranslateService) {

        let serverURL = localStorage.getItem(LocalStorageConstants.SERVER_URL)
        let appId = localStorage.getItem(LocalStorageConstants.APP_ID)

        if (serverURL == null) {
            localStorage.setItem(LocalStorageConstants.APP_ID, environment.parseAppID)
            localStorage.setItem(LocalStorageConstants.SERVER_URL, environment.parseServerUrl)
            localStorage.setItem(LocalStorageConstants.APP_MODE, "Production")
            serverURL = environment.parseServerUrl
            appId = environment.parseAppID
        }

        Parse.initialize(appId);
        Parse.serverURL = serverURL;
    }

    /**
     * Method to call a cloud function in Parse
     * @param functionName name of the function to be called
     * @param params parameters needed to be passed to the function if any
     */
    async executeFunction(functionName: string, params: any, isPublic = false): Promise<ResponseModel<any>> {
        if (isPublic) {
            params.versionFingerprint = environment.versionFingerprint;
        }
        
        return await Parse.Cloud.run(functionName, params);
    }
}