import { Injectable } from "@angular/core";
import { ResponseModel } from 'src/app/global/model/response.model';
import { ServerConstants } from 'src/app/global/model/server.constants';
import { TraceyParseService } from 'src/app/global/services/tracey-parse.service';
import { environment } from 'src/environments/environment';
import { LocalStorageConstants } from 'src/app/global/model/local-storage.constants';
import info from '../../../../package.json';

@Injectable()
export class VersionService {
    constructor(private parseService: TraceyParseService) { }

    public async validateVersionFingerprint(): Promise<boolean> {
        const versionParams = {
            versionFingerprint: environment.versionFingerprint
        }
        let response: ResponseModel<boolean> = await this.parseService.executeFunction('validateVersionFingerprint', versionParams);
        return response.statuscode === ServerConstants.SUCCESS && response.data;
    }

    public checkIfUpdated(): void {
        let savedAppVersion = localStorage.getItem(LocalStorageConstants.APP_VERSION);
        if (savedAppVersion === null || savedAppVersion !== info.version) {
            this.clearCacheAndSetLocalVariables()
        }
    }
    public clearCacheAndSetLocalVariables(): void {
        const prod: boolean = localStorage.getItem(LocalStorageConstants.APP_MODE) === "Production";
        localStorage.clear();
        localStorage.setItem(LocalStorageConstants.APP_ID, prod ? environment.parseAppID : environment.testAppID);
        localStorage.setItem(LocalStorageConstants.SERVER_URL, prod ? environment.parseServerUrl : environment.testServerUrl);
        localStorage.setItem(LocalStorageConstants.APP_MODE, prod ? "Production" : "Testing");
        localStorage.setItem(LocalStorageConstants.APP_VERSION, info.version);
    }
}