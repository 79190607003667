import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AlertOptions } from '@ionic/angular';
import { LocalStorageConstants } from '../model/local-storage.constants';
import { AlertService } from './alert.service';

@Injectable()
export class TranslateOptionService {

    constructor(public translate: TranslateService, private alertService: AlertService) {
        const lang = localStorage.getItem(LocalStorageConstants.APP_LANGUAGE);
        if (!lang) {
            localStorage.setItem(LocalStorageConstants.APP_LANGUAGE, 'de');
            translate.setDefaultLang('de');
        } else {
            translate.setDefaultLang(lang);
        }
    }

    async showAlert(): Promise<void> {
        const currentLanguage = localStorage.getItem(LocalStorageConstants.APP_LANGUAGE);

        const alertOptions: AlertOptions = {
            header: this.translate.instant('LANGUAGES.CHOOSE_LANGUAGE'),
            inputs: [
                {
                    type: 'radio',
                    label: this.translate.instant('LANGUAGES.ENGLISH'),
                    value: 'en',
                    checked: currentLanguage === 'en'
                },
                {
                    type: 'radio',
                    label: this.translate.instant('LANGUAGES.GERMAN'),
                    value: 'de',
                    checked: currentLanguage === 'de'
                }
            ],
            buttons: [
                {
                    text: this.translate.instant('CANCEL')
                },
                {
                    text: this.translate.instant('CONFIRM'),
                    handler: language => {
                        this.translate.use(language);
                        localStorage.setItem(LocalStorageConstants.APP_LANGUAGE, language);
                    }
                }
            ]
        };
        await this.alertService.showAlert(alertOptions);
    }
}
