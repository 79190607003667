import { Injectable, Inject } from "@angular/core";
import { MenuController, NavController } from '@ionic/angular';
import { TranslateOptionService } from './translate-option.service';
import { Dialogs } from '@awesome-cordova-plugins/dialogs/ngx';
import { ThemeSwitcherService } from './theme-switcher.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { LocalStorageConstants } from '../model/local-storage.constants';
import { AuthenticationService } from '@pagmf/security';
import { VersionService } from './version.service';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
})
export class SideBarService {

    private menuId: string = "first"
    public updateUserInfo = new Subject<string>()
    public showLogout: boolean = false;
    public username: string = '';
    public userrole: string = '';

    constructor(
        private menu: MenuController,
        private translateOptionService: TranslateOptionService,
        private dialogs: Dialogs,
        private themeSwitcher: ThemeSwitcherService,
        private navCtrl: NavController,
        private translate: TranslateService,
        private versionService: VersionService,
        private sessionService: SessionService,
        @Inject('AuthenticationService') private authenticationService: AuthenticationService
    ) {

        this.updateUserInfo.subscribe(() => {
            this.username = localStorage.getItem(LocalStorageConstants.USER_NAME)
            this.userrole = localStorage.getItem(LocalStorageConstants.ROLE)
        })
    }

    enableSidebar() {
        this.menu.enable(true, this.menuId);

    }

    disableSidebar() {
        this.menu.enable(false, this.menuId);
    }

    async openSidebar() {
        await this.menu.open(this.menuId);
    }

    async closeSidebar() {
        await this.menu.close(this.menuId);
    }

    async languageSwitchOption() {
        this.closeSidebar();
        this.translateOptionService.showAlert()
    }

    async switchThemeOption() {
        this.closeSidebar();
        this.themeSwitcher.showAlert();
    }

    async showExplantion() {
        // TODO - Complete Flow
        this.closeSidebar();
        console.log("Explanation Clicked");
    }

    async changeAppMode() {
        this.closeSidebar();
        this.navCtrl.navigateForward('/app-mode-scanner')
    }

    async logoutOption() {
        this.closeSidebar();
        this.dialogs.confirm(this.translate.instant('LOGOUT_CONFIRMATION'), "Tracey").then((result) => {
            if (result === 1) {
                this.authenticationService.logout().then(() => {
                    console.log("logout successful");
                });
                this.sessionService.authenticationState.next(false);
                this.versionService.clearCacheAndSetLocalVariables();
                this.navCtrl.navigateBack('/landing');
            }
        });
    }
}