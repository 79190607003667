import { Injectable } from '@angular/core';

@Injectable({
  providedIn : 'root'
})
export class PagLabelService {

  getLabelStyle() {
    return `<style>.pag-label{
      width: 375px;
      height: 200px;
      border: 1px solid black;
      display: flex;
      padding: 5px;
      align-items: center;
      margin : 10px;
    }
    
    .data-matrix-code{
      width: 100px;
      height: 100px;
      margin: 5px;
    }
    
    .label-table{
    
      margin: 5px;
      flex-grow: 8;
      border-collapse: collapse;
      border: 1px solid black;
  
      th, td {
          padding: 5px;
          border: 1px solid black;
      }
    
    }
    </style>`
  }

}