import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonDatetime, IonicModule } from '@ionic/angular';
import { ThemeSwitcherService } from './services/theme-switcher.service';
import { ToastService } from './services/toast.service';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { TraceyParseService } from './services/tracey-parse.service';
import { ScannerService } from './services/scanner.service';
import { MenuButtonComponent } from './components/menu-button/menu-button.component';
import { HighlightSearchPipe } from './pipes/highlight-search.pipe';
import { PagLabelService } from '../features/common/services/pag-label.service';
import { OrderInboxExpandableComponent } from '../features/outsource-material/component/order-inbox-expandable/order-inbox-expandable.component';
import { LabelScanService } from '../features/incoming-goods/services/label-scan.service';
import { MaterialListPage } from '../features/common/pages/material-list/material-list.page';
import { SupplierListPage } from '../features/common/pages/supplier-list/supplier-list.page';
import { TextInputPage } from '../features/common/pages/text-input/text-input.page';
import { DateInputPage } from '../features/common/pages/date-input/date-input.page';
import { CommonService } from '../features/common/services/common.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PagLabelComponent } from '../features/common/components/pag-label/pag-label.component';
import { AppendBoxUnitPipe } from './pipes/append-box-unit.pipe';
import { LabelListPage } from '../features/common/pages/label-list/label-list.page';
import { PrintLabelModalComponent } from './components/print-label-modal/print-label-modal.component';
import { FeatureService } from './services/feature.service';
import { MaterialInfoService } from './services/material-info.service';
import { ReactiveFormsModule } from '@angular/forms';
import { ScannerComponent } from './components/scanner/scanner.component';
import { VersionComponent } from './components/version/version.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    LabelListPage, 
    BackButtonComponent, 
    MenuButtonComponent, 
    HighlightSearchPipe, 
    PagLabelComponent,
    OrderInboxExpandableComponent, 
    MaterialListPage, 
    SupplierListPage, 
    TextInputPage,
    DateInputPage,
    AppendBoxUnitPipe,
    PrintLabelModalComponent,
    ScannerComponent,
    VersionComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule.forChild({loader: {
      provide: TranslateLoader,
      useFactory:(createTranslateLoader),
      deps: [HttpClient]
    }})
  ],
  providers : [
    TraceyParseService,
    ThemeSwitcherService,
    ToastService,
    ScannerService,
    PagLabelService,
    LabelScanService,
    CommonService,
    FeatureService,
    MaterialInfoService
  ],
  exports: [
    LabelListPage,
    BackButtonComponent,
    MenuButtonComponent,
    HighlightSearchPipe,
    PagLabelComponent,
    OrderInboxExpandableComponent,
    MaterialListPage,
    SupplierListPage,
    TextInputPage,
    DateInputPage,
    AppendBoxUnitPipe,
    ReactiveFormsModule,
    ScannerComponent,
    VersionComponent

  ]
})
export class SharedModule { }