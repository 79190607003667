<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>
      {{"SELECT_MATERIAL_TYPE"|translate}}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div slot="fixed">
    <ion-grid>
      <ion-row class="ion-align-items-center ion-justify-content-center" style="margin-top: 10px;">
        <ion-col style="display : inherit;">
          <ion-searchbar (ionChange)="searchValueChanged($event)"></ion-searchbar>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <div style="margin-top: 50px;">
    <ion-list>
      <ion-item *ngFor=" let materialType of filteredList" (click)="materialSelected( materialType )"  >
        <div [innerHTML]="materialType.partNumber| highlight: searchedString">
        </div>
      </ion-item>
    </ion-list>
  </div>
</ion-content>
