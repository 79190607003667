import { Component } from '@angular/core';
import packageJson from '../../../../../package.json'
@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
})
export class VersionComponent {
  version = packageJson.version

  refreshApp() {
    window.location.reload()
  }
}
