import { Injectable } from '@angular/core';
import { Material } from '../model/material';
import { MaterialType } from '../model/material-type.model';

export interface MaterialInfoLabel {
  date: string,
  time: string,
  materialType: string,
  batchNo: string,
  rollNo: string,
  remainingShelfLife: any,
}

@Injectable({
  providedIn: 'root'
})

export class ZplCodeService {

  // in dpmm
  labelHeight = 400;
  maxCount = 19;

  getZplCode(materialType : MaterialType, material : Material, labelNo = 0) {
    let materialName = materialType.partNumber.match(new RegExp('.{1,' + this.maxCount + '}', 'g'));

    let materialNameCode = ""

    for (let index = 0; index < materialName.length; index++) {
      materialNameCode += `      
      ^CFA,22
      ^FO360,${ labelNo * this.labelHeight + 165 + (index*20)}^FD` + materialName[index] + `^FS`
    }

    return `
    ^XA

        ^FO20,${ labelNo * this.labelHeight + 100}
        ^BQN,2,5
        ^FDQA,`+ material.productId + `^FS
        
        ^FO210,${ labelNo * this.labelHeight + 110 }^GB385,${160 + ((materialName.length - 1) *20)},3^FS
        ^FO353,${ labelNo * this.labelHeight + 110 }^GB1,${160 + ((materialName.length - 1) *20)},3^FS
        
        ^FO210,${ labelNo * this.labelHeight + 150}^GB385,1,3^FS
        
        ^CFA,22
        ^FO220,${ labelNo * this.labelHeight + 125}^FD` + "Supplier" + `^FS
        ^CFA,22
        ^FO360,${ labelNo * this.labelHeight + 125}^FD` + materialType.supplier + `^FS
        
        ^FO210,${ labelNo * this.labelHeight + 190 + ((materialName.length - 1) *20)}^GB385,1,3^FS
        
        ^CFA,22
        ^FO220,${ labelNo * this.labelHeight + 165}^FD` + "Material" + `^FS
        ${ materialNameCode }
        
        
        ^FO210,${ labelNo * this.labelHeight + 230 + ((materialName.length - 1) *20) }^GB385,1,3^FS
        
        ^CFA,22
        ^FO220,${ labelNo * this.labelHeight + 205 + ((materialName.length - 1) *20)}^FD` + "Batch-No." + `^FS
        ^CFA,22
        ^FO360,${ labelNo * this.labelHeight + 205 + ((materialName.length - 1) *20)}^FD` + material.batchOrLotNumber + `^FS
        
        ^CFA,22
        ^FO220,${ labelNo * this.labelHeight + 240 + ((materialName.length - 1) *20)}^FD` + "Roll/Bucket" + `^FS
        ^CFA,22
        ^FO360,${ labelNo * this.labelHeight + 240 + ((materialName.length - 1) *20)}^FD` + material.rollOrBucketNumber + `^FS
        
        
        ^CF0,190
    ^XZ`
  }

  getZplCode5C(materialType : MaterialType, material : Material, labelNo = 0) {
    let materialName = materialType.partNumber.match(new RegExp('.{1,' + this.maxCount + '}', 'g'));

    let materialNameCode = ""

    for (let index = 0; index < materialName.length; index++) {
      materialNameCode += `      
      ^CFA,22
      ^FO360,${ labelNo * this.labelHeight + 145 + (index*20)}^FD` + materialName[index] + `^FS`
    }

    return `
    ^XA
          
          
          ^FO20,${ labelNo * this.labelHeight + 100}
          ^BQN,2,5
          ^FDQA,`+ material.productId + `^FS
          
          ^FO210,${ labelNo * this.labelHeight + 90}^GB385,${205 + ((materialName.length - 1) *20)},3^FS
          ^FO353,${ labelNo * this.labelHeight + 90}^GB1,${205 + ((materialName.length - 1) *20)},3^FS
          
          ^FO210,${ labelNo * this.labelHeight + 130}^GB385,1,3^FS
          
          ^CFA,22
          ^FO220,${ labelNo * this.labelHeight + 105}^FD` + "Supplier" + `^FS
          ^CFA,22
          ^FO360,${ labelNo * this.labelHeight + 105}^FD` + materialType.supplier + `^FS
          
          ^FO210,${ labelNo * this.labelHeight + 170 + ((materialName.length - 1) *20)}^GB385,1,3^FS
          
          ^CFA,22
          ^FO220,${ labelNo * this.labelHeight + 145}^FD` + "Material" + `^FS
          ${ materialNameCode }
        
          
          ^FO210,${ labelNo * this.labelHeight + 210 + ((materialName.length - 1) *20)}^GB385,1,3^FS
          
          ^CFA,22
          ^FO220,${ labelNo * this.labelHeight + 185 + ((materialName.length - 1) *20)}^FD` + "Batch-No." + `^FS
          ^CFA,22
          ^FO360,${ labelNo * this.labelHeight + 185 + ((materialName.length - 1) *20)}^FD` + material.batchOrLotNumber + `^FS
          
          ^CFA,22
          ^FO220,${ labelNo * this.labelHeight + 225 + ((materialName.length - 1) *20)}^FD` + "Roll/Bucket" + `^FS
          ^CFA,22
          ^FO360,${ labelNo * this.labelHeight + 225 + ((materialName.length - 1) *20)}^FD` + material.rollOrBucketNumber + `^FS

          ^FO210,${ labelNo * this.labelHeight + 250 + ((materialName.length - 1) *20)}^GB385,1,3^FS
        
          ^CFA,22
          ^FO220,${ labelNo * this.labelHeight + 265 + ((materialName.length - 1) *20)}^FD` + "Work Area" + `^FS
          ^CFA,22
          ^FO360,${ labelNo * this.labelHeight + 265 + ((materialName.length - 1) *20)}^FD` + material.workArea + `^FS
          
          
          ^CF0,190
    ^XZ`
  }

  getZplCodeForInfo(lableValues : MaterialInfoLabel) {

    let materialNameCode = ""

    if( lableValues.materialType.length <= 13 ){
      materialNameCode = `
      ^CFA,22
      ^FO140,143^FD${ lableValues.materialType }^FS`
    } else if( lableValues.materialType.length > 13 && lableValues.materialType.length <= 18) {
      materialNameCode = `
      ^CFB,10
      ^FO140,143^FD${ lableValues.materialType }^FS`
    } else {
      materialNameCode = `
      ^CFB,10
      ^FO140,143^FD${ lableValues.materialType.slice(0, 18) }^FS
      
      ^CFB,10
      ^FO140,163^FD${ lableValues.materialType.slice(18, 36) }^FS`
    }

    return `
  
    ^XA

      ^FO16,128^GB560,144,3^FS
      ^FO304,128^GB1,144,3^FS
      ^FO16,176^GB288,1,3^FS
      ^FO16,224^GB560,1,3^FS
      ^FO400,128^GB1,96,3^FS
      ^FO400,176^GB176,1,3^FS

      
      ^CFA,22
      ^FO25,143^FDMaterial: ^FS
      ${ materialNameCode }

      ^CFA,22
      ^FO25,191^FD${ "Batch-No: " + lableValues.batchNo }^FS

      ^CFA,22
      ^FO25,239^FD${ "Roll-No: " + lableValues.rollNo }^FS

      ^CFA,22
      ^FO410,143^FD${ "Date:" + lableValues.date  }^FS

      ^CFA,22
      ^FO410,191^FD${ "Time: " + lableValues.time }^FS

      ^CFA,22
      ^FO314,143^FDOut-^FS
      ^CFA,22
      ^FO314,168^FDsourced^FS

      ^CFA,22
      ^FO314,239^FD${ "Shelf Life: " + lableValues.remainingShelfLife }^FS

    ^XZ
    `
  }
}