export enum LocalStorageConstants{
  USER_CODE = "userCode",
  USER_NAME = "userName",
  ROLE = "role",
  APP_THEME = "theme",
  APP_LANGUAGE = "lang",
  OBJECT_ID = "objectId",
  PRODUCT_IDS = "productIds",
  APP_ID = "appId",
  SERVER_URL = "serverUrl",
  APP_MODE = "appMode",
  APP_VERSION = "appVersion",

}