import { Injectable } from '@angular/core';
import { TraceyParseService } from 'src/app/global/services/tracey-parse.service';
import { ResponseModel } from 'src/app/global/model/response.model';
import { ServerConstants } from 'src/app/global/model/server.constants';

@Injectable({
    providedIn: 'root'
})
export class MaterialInfoService {

    constructor(private parseService: TraceyParseService) { }


    async getProductByProductId(productId: string) {
        let params = {
            productId: productId,
        }
        let result: ResponseModel<any> = await this.parseService.executeFunction('fetchProduct', params);
        if (result.statuscode == ServerConstants.SUCCESS) {
            return result;
        } else {
            console.log(result);
            throw new Error(result.message);
        }

    }
}
