import { Component } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier-list.page.html',
})
export class SupplierListPage {

  constructor(private translate: TranslateService,
    private commonService: CommonService,
    private navCtrl: NavController) { }

  supplierSelected(supplier: string) {
    this.navCtrl.pop();
    this.commonService.selectedSupplierSubject.next(supplier);
  }
}
