import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { ToastController } from '@ionic/angular';

@Injectable()
export class ToastService {

    constructor(public toastController: ToastController, private translate: TranslateService) {}

    /**
     * Method to display a toast
     * @param message 
     */
    showToast(message: string) {
        this.toastController.create({
          message: this.translate.instant(message),
          position: 'bottom',
          duration: 3000,
          cssClass: 'toast-custom-class',
          buttons: []
        }).then((toast) => {
          toast.present();
        });
      } 
}