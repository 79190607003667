import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-text-input',
  inputs: ['textInput'],
  templateUrl: './text-input.page.html',
})
export class TextInputPage {

  @Input() defaultValue: string;
  
  textInputForm: UntypedFormGroup = new UntypedFormGroup({
    textInput: new UntypedFormControl(null, Validators.required),
  })

  constructor(private modalController: ModalController) { }

  async ngOnInit() {
    this.textInputForm.controls.textInput.setValue(this.defaultValue);
  }

  onOK() {
    this.onDismiss(this.textInputForm.get("textInput").value);
  }

  onDismiss(data: string = "") {
    this.modalController.dismiss(data);
  }

  back() {
    this.modalController.dismiss(undefined);
  }

  // getValue() {
  //   return this.textInputForm.get("textInput").value;
  // }

}
