import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import * as Scandit from 'scandit-sdk'
import { environment } from 'src/environments/environment';

const scanditLicenseKey: string =
  'ASwTUSqCD793ByfWqyvdidE6gco/M4h85loPBvJbTko5W0ubqlMajyFtteUbChHgRm7uvvtrMaAxa3uCm31GlLUHCVMDTIu0qEplI39qpI/1T5rJQDQfqpVZ5dfLW/W0JnrLiZxpp1s2HQkxmnlVS5AWIOD0GvTkjj3NgeI+QiMqaVB3nMZqIFc1KErPIaYOlQys4BIFim3hiL9ivwiz0Nc9f8syPqFZgYlStPrCluQ+WwvLSwDiasjHFqyky+p1Ae7rwR1KRAxgaQsyvIA0K2C4jUgACHRj//OVeSrhUXlDcjAXERc0FXbZ5FcK7As723KEZPEDANyUJaL5GRMNRpQZw02oyKIn3qq+B6T/gb/hFZaPBcHzPir5vUjDCQbN+yLABdlLEbs30/eG3UO2S8FRoNAGbPxHHtNcaFBugoHxLLLzSq191xQmC4GdECmRjlmPH7m2YOLmas+Q8SUlDfHu3a0btjFRg/yhPDlGPNP3clJym6+zcSg/DV6jhsNC69nbiCoMZAf4voTXqm3/wu9xSTEIq61DH3aLgf0l1Lddx9xf+J45CinGhEui7vKWRx+1lRP1Hj8SSx1IA/DWJXsDzPG5taUAYRmEjDZaEuGg9YiGaYFrKw2JHrCaK6vBRCI6MhlyNDTImAz5oUW3c9pQmxuLel7RX3FCW/0eE8LlIMiafP7zc5n0HbZiHSHgJ1sJA7I4RdD6slmuDDcqSZ9VL5CwiMLCWrfJ9iP9dHbp77DedICJ1Szh2ADi0QpNqnu4EOSPFVussg+lQACq8wIxRbVPAYiGxAe7ekkzOYSQCgNV9vi4rIg76g60GqArLnJAAMn4RjQRjpfP0Nvz8w=='

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
})
export class ScannerComponent implements OnInit, OnDestroy {
  private picker: BarcodePicker;

  private defaultBarcodes: Scandit.Barcode.Symbology[] = [
    Scandit.Barcode.Symbology.CODE128,
    Scandit.Barcode.Symbology.QR,
    Scandit.Barcode.Symbology.UPCA,
    Scandit.Barcode.Symbology.UPCE,
    Scandit.Barcode.Symbology.CODE39
  ]


  @Input() fullScannerHeight: boolean = true;
  @Output() scannedCode: EventEmitter<string> = new EventEmitter<string>()
  @ViewChild('scannerElement') scannerElement: ElementRef<HTMLDivElement>
  @Input() scanSettings?: ScanSettings = new Scandit.ScanSettings({
    enabledSymbologies: this.defaultBarcodes,
    codeDuplicateFilter: -1,
  })
  public scanSettingBehaviorObj: BehaviorSubject<ScanSettings> = new BehaviorSubject<ScanSettings>(this.scanSettings);
  private settingSub: Subscription;

  constructor() { }

  async ngOnInit() {
    await this.initialize();
  }


  private async initialize() {
    await Scandit.configure(environment.scanditKey, {
      engineLocation:   'https://apps.de.pa.corp/tracey-desk/pont3/pwa/assets/scanditEngine',
    })
    await this.show();
  }
  ngOnDestroy() {
    this.hide()
    this.settingSub.unsubscribe();
  }


  public setScannerSettings(barcodes: Scandit.Barcode.Symbology[]) {
    this.scanSettingBehaviorObj.next(new Scandit.ScanSettings({ enabledSymbologies: barcodes }));
  }

  async show(): Promise<void> {
    if (this.picker) {
      if (!this.settingSub) {
        this.subscribeToScanSettings();
      }
    } else {
      Scandit.BarcodePicker.create(
        this.scannerElement.nativeElement,
        {
          scanSettings: this.scanSettings,
        }
      ).then((picker) => {
        this.picker = picker;
        // barcodePicker is ready here, show a message every time a barcode is scanned
        this.subscribeToScanSettings();
        picker.on('scan', (scanResult) => {
          this.scannedCode.emit(scanResult.barcodes[0].data)
        })
      });
    }
  }

  private subscribeToScanSettings(): void {
    this.settingSub = this.scanSettingBehaviorObj.subscribe((settings) => {
      this.scanSettings = settings;
      this.picker.applyScanSettings(this.scanSettings)
    });
  }

  resumeScanning() {
    if (this.picker) {
      this.picker.resumeScanning();
    } else {
      this.initialize();
    }
  }

  pauseScanning() {
    this.picker.pauseScanning();
  }
  hide() {
    if (this.picker) {
      this.picker.destroy()
    }
  }

}
