<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>{{"SELECT_SUPPLIER"|translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div style="height: 100%; overflow: hidden;">
    <ion-list>
      <ion-item (click)="supplierSelected('3M')" >3M</ion-item>
      <ion-item (click)="supplierSelected('Gurit')" >Gurit</ion-item>
      <ion-item (click)="supplierSelected('HEXCEL')" >Hexcel</ion-item >
      <ion-item (click)="supplierSelected('LOCTITE')" >Loctite</ion-item>
      <ion-item (click)="supplierSelected('SOLVAY')" >Solvay</ion-item>
    </ion-list>
  </div>
</ion-content>
