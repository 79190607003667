import { Component } from '@angular/core';
import { SideBarService } from '../../services/side-bar.service';

@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
})
export class MenuButtonComponent {

  constructor( private sideBarService : SideBarService ) { }

  openSidebar() {
    this.sideBarService.openSidebar();
  }

}
