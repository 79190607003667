import { Injectable, ErrorHandler, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ServerErrorMessage } from '../model/server.constants';
import { SessionService } from '../services/session.service';
import { ToastService } from '../services/toast.service';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(
        private sessionService: SessionService,
        private zone: NgZone,
        private toastService: ToastService,
        private translate: TranslateService) { }
    handleError(error: any): void {
        console.log(error);
        if (error.message && error.message.includes(ServerErrorMessage.INVALID_SESSION_TOKEN)) {
            this.zone.run(async () => {
                await this.sessionService.onSessionExpired();
            })
        } else {
            this.toastService.showToast(this.translate.instant(error.message ? error.message : 'UNKOWN_ERROR_MESSAGE'));
        }
    }
}