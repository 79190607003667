import { Injectable } from '@angular/core';
import { AlertController, AlertOptions } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private alert: HTMLIonAlertElement;

  constructor(private alertController: AlertController) {
  }

  async showAlert(options: AlertOptions): Promise<void> {
    await this.alert?.dismiss();
    this.alert = await this.alertController.create(options);
    await this.alert.present();

  }
}
