import { Injectable } from '@angular/core';
import { MaterialTypeService } from '../../common/services/material-type.service';
import { Supplier, MaterialType } from '../../common/model/material-type.model';
import { SUPPLIER_SCAN_REGEX } from 'src/app/global/utils/constants';

@Injectable()
export class LabelScanService {

  private cachedMaterialList = new Array<MaterialType>();

  public readonly supplierScanTemplate = {
    "propertyName": "supplier",
    "propertyTitle": "SUPPLIER",
    "regexForScan": SUPPLIER_SCAN_REGEX,
    "regexForValue": "",
    "description": ""
  };
  public readonly materialTypeScanTemplate = {
    "propertyName": "materialType",
    "propertyTitle": "Part Number",
    "regexForScan": "",
    "regexForValue": "",
    "description": ""
  };
  public readonly rollOrBucketNumberScanTemplate = {
    propertyName: "rollOrBucketNumber",
    propertyTitle: "BOX_NUMBER",
    regexForScan: null,
    regexForValue: "",
    description: ""
  };
  public readonly guritScanTemplate = {
    "propertyName": "guritDMC",
    "propertyTitle": "Data Matrix Code",
    "regexForScan": null,
    "regexForValue": "",
    "description": ""
  };

  constructor(private materialTypeService: MaterialTypeService) { }

  async getMaterialTypeScanRegex(supplier: Supplier): Promise<string> {

    let materialTypeList = await this.materialTypeService.getMaterialTypesForSupplier(supplier);
    this.cachedMaterialList = materialTypeList;
    let regexList = [];

    materialTypeList.forEach((element) => {
      if (element.regexPattern) {
        regexList.push(element.regexPattern)
      }
    })

    let regexString = "(" + regexList.join('|') + ")";
    return regexString;
  }

  identifyMaterialTypeFromScan(scannedString: string): MaterialType {
    console.log("scanned string", scannedString);
    let index = 0;
    for (; index < this.cachedMaterialList.length; index++) {
      var regex = new RegExp(this.cachedMaterialList[index].regexPattern)
      console.log("regex", regex);
      if (regex.test(scannedString)) {
        break;
      }
    }
    return this.cachedMaterialList[index];
  }

  async getMaterialtypeForGuritScan(splittedText: string[]): Promise<MaterialType> {
    try {
      /*
      if (
        (splittedText.length < 3)
        || (splittedText[0].trim().match('\\d{7}') === null)
        || (splittedText[2].trim().match('\\d{12}') === null)
        || (Number.isNaN(parseInt(splittedText[1].trim().replace(',', '.'))))
      ) {
        throw new Error();
      } else {
        console.log("getMaterialBySupplierIdentNumber:", splittedText[0].trim());
        
        return this.materialTypeService.getMaterialBySupplierIdentNumber(splittedText[0].trim());
      }
      */

      if (
        (splittedText.length < 1)
        || (splittedText[0].trim().match('\\d{7}') === null)
      ) {
        throw new Error();
      } else {
        return this.materialTypeService.getMaterialBySupplierIdentNumber(splittedText[0].trim());
      }
    } catch (error) {
      console.log("getMaterialtypeForGuritScan error:", error);
      
      return null;
    }
  }
}