<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      {{'PRINT_LABEL' | translate}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onDismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid *ngIf='selectDevice'>
    <ion-row>
      <ion-col>
        <ion-list-header lines="inset">
          <ion-label color="primary">{{'BT_BONDED_DEVICES' | translate}}</ion-label>
        </ion-list-header>
        <ion-list>
          <ion-item *ngFor="let device of pairedDevices" button (click)="connect(device)">
            <ion-label>{{ device.name }}</ion-label>
            <ion-label slot="end" class="mac-address">{{ device.address }}</ion-label>
          </ion-item>
        </ion-list>
        <ion-list-header lines="inset">
          <ion-label color="primary">{{'BT_AVAILABLE_DEVICES' | translate}}</ion-label>
          <ion-button [disabled]="scanning" (click)="discoverUnpaired()">{{ 'SCAN' | translate }}</ion-button>
        </ion-list-header>
        <ion-list>
          <div class="ion-padding" *ngIf="scanning">
            <ion-spinner name="lines"></ion-spinner>
          </div>
          <ion-item *ngFor="let device of unpairedDevices" button (click)="connect(device)">
            <ion-label>{{ device.name }}</ion-label>
            <ion-label slot="end" class="mac-address">{{ device.address }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>