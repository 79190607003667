<div class="pag-label">
  <img class="data-matrix-code" [src]="imgSrc" alt="data-matrix-code">
  <table class="label-table table table-hover">
    <tbody>
      <tr *ngFor="let number of numbers ">
        <td>{{ headers[number] }}</td>
        <td>{{ values[number] }}</td>
      </tr>
    </tbody>
  </table>
</div>