import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-date-input',
  inputs: ['dateInput'],
  templateUrl: './date-input.page.html',
})
export class DateInputPage {

  @Input() defaultValue: string;

  dateInputForm: UntypedFormGroup = new UntypedFormGroup({
    dateInput: new UntypedFormControl(null, Validators.required),
  })

  minDate: number = new Date().getFullYear() - 5;
  maxDate: number = new Date().getFullYear();

  constructor(private modalController: ModalController) { }

  async ngOnInit() {
    this.dateInputForm.controls.dateInput.setValue(this.defaultValue);
  }

  onOK() {
    const dateInput = this.dateInputForm.get("dateInput").value;
    if (dateInput) {
      // this.onDismiss(new Date(dateInput).toLocaleDateString(undefined));
      this.onDismiss(new Date(dateInput).toLocaleDateString("de-DE"));
    } else {
      this.onDismiss(`--------`);
    }
    
  }

  onDismiss(data: string = "") {
    this.modalController.dismiss(data);
  }

  back() {
    this.modalController.dismiss(undefined);
  }

}
