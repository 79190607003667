<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>{{"LABELS" | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-grid class="gridStyle" id="print-canvas">
    <ion-row *ngFor="let material of materialList; let i = index">
      <div *ngIf="i == 0">
        <ion-col size="12">
          <app-pag-label [barcodeId]="material.productId" [headers]="labelHeaders" [values]="labelValues[i]">
          </app-pag-label>
        </ion-col>
      </div>
    </ion-row>
  </ion-grid>
  <ion-label *ngIf="rollBucketArray.length > 0">{{"ROLL_BUCKET_NUMBERS" | translate}} : {{rollBucketArray}}
    {{"WILL_BE_PRINTED" | translate}}</ion-label>
  <ion-button *ngIf="!printLabelForOrder" class="btn" expand="full" (click)="nextItem()">
    {{"NEXT_ITEM_ON_PALLET" | translate}}</ion-button>
  <ion-button *ngIf="!printLabelForOrder" class="btn" expand="full" (click)="finishPalletClick()">
    {{"FINISH_PALLET" | translate}}</ion-button>
  <ion-button *ngIf="!printLabelForOrder" class="btn" expand="full" (click)="checkDeliveyNote()">
    {{"CHECK_DELIVERY_NOTE" | translate}}</ion-button>


  <ion-button *ngIf="printLabelForOrder" class="btn" expand="full" (click)="nextOrder()">
    {{"NEXT_ORDER" | translate}}</ion-button>
  <ion-button *ngIf="printLabelForOrder" class="btn" expand="full" (click)="finishOrder()">
    {{"FINISH_ORDER" | translate}}</ion-button>
</ion-content>