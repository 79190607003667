import { Injectable } from '@angular/core';
import { TraceyParseService } from 'src/app/global/services/tracey-parse.service';
import { Material } from '../../common/model/material';
import { LocalStorageConstants } from 'src/app/global/model/local-storage.constants';
import { ResponseModel } from 'src/app/global/model/response.model';
import { ServerConstants } from 'src/app/global/model/server.constants';

@Injectable()
export class IncomingGoodsService {

  constructor(private parseService: TraceyParseService) { }

  async addProduct(material: Material, endRoll: string): Promise<any> {
    let params = {
      productId: material.productId,
      batchOrLotNumber: material.batchOrLotNumber,
      rollOrBucketNumber: material.rollOrBucketNumber,
      dispatchDate: material.dispatchDate,
      deliveryDate: material.deliveryDate,
      manufacturingDate: material.manufacturingDate,
      identNumber: material.identNumber,
      goodsReceipt: "",
      totalQuantity: material.totalQuantity,
      lastModifiedBy: localStorage.getItem(LocalStorageConstants.OBJECT_ID),
      endRoll
    }
    if (endRoll) {
      params.endRoll = endRoll
    }
    console.log("ADD PRODUCT request", params);
    let result: ResponseModel<any> = await this.parseService.executeFunction('addProduct', params);
    console.log("ADD PRODUCT MAIN result", result);
    if (result.statuscode == ServerConstants.SUCCESS) {
      console.log("add product result", result);
      return result.data;
    } else {
      throw new Error(result.message);
    }
  }

  /**
    * Method to fetch latest box number.
    * @param identNumber 
    *  * @param batchOrLotNumber
    */
  async getLatestBoxNumber(identNumber: string, batchOrLotNumber: string) {
    let params = {
      identNumber,
      batchOrLotNumber
    }
    let result: ResponseModel<string> = await this.parseService.executeFunction('fetchRollOrBucketNumber', params);
    if (result.statuscode == ServerConstants.SUCCESS) {
      return result.data;
    } else {
      throw new Error(result.message);
    }
  }

  async getLatestPalletBuffer() {
    let params = {};
    let result: ResponseModel<string> = await this.parseService.executeFunction('getAvailablePalletBuffer', params);
    if (result.statuscode == ServerConstants.SUCCESS) {
      return result.data;
    } else {
      throw new Error(result.message);
    }
  }

  async finishPallet(productIds: any[], palletBuffer: number) {
    let params = {
      productIds: productIds,
      palletBuffer: palletBuffer,
      lastModifiedBy: localStorage.getItem(LocalStorageConstants.OBJECT_ID)
    }
    let result: ResponseModel<string> = await this.parseService.executeFunction('storeMaterialToPalletBuffer', params);
    if (result.statuscode == ServerConstants.SUCCESS) {
      return result.data;
    } else {
      throw new Error(result.message);
    }
  }

  async doesProductExists(material: Material, endRoll: string): Promise<any> {
    let params = {
      productId: material.productId,
      batchOrLotNumber: material.batchOrLotNumber,
      rollOrBucketNumber: material.rollOrBucketNumber,
      dispatchDate: material.dispatchDate,
      deliveryDate: material.deliveryDate,
      manufacturingDate: material.manufacturingDate,
      identNumber: material.identNumber,
      goodsReceipt: "",
      totalQuantity: material.totalQuantity,
      lastModifiedBy: localStorage.getItem(LocalStorageConstants.OBJECT_ID),
      endRoll
    }
    if (endRoll) {
      params.endRoll = endRoll
    }
    let result: ResponseModel<any> = await this.parseService.executeFunction('isProductExist', params);
    if (result.statuscode == ServerConstants.SUCCESS) {
      return result.data;
    } else {
      throw new Error(result.message);
    }
  }
}