<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="" (click)="back()"></ion-back-button>
    </ion-buttons>
    <ion-title>
      {{"BATCH"|translate}}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div slot="fixed">
    <ion-grid>
      <ion-row class="ion-align-items-center ion-justify-content-between" style="width: 97vw; margin-top: 10px;">
        <ion-col>
          <form [formGroup]="textInputForm">
            <ion-list>
              <ion-item>
                <!-- <ion-label>{{"BATCH" | translate}}</ion-label> -->
                <ion-input class="ion-text-end" formControlName="textInput" placeholder="{{'BATCH_NO' |translate}}">
                </ion-input>
              </ion-item>
            </ion-list>
          </form>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button class="btn" type="submit" shape="round" expand="full" (click)="onOK()"
            [disabled]="!textInputForm.valid">
            {{'SELECT' |translate}}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
