// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  parseAppID: "Jr4BEttGbur54BsBJ6fzpPjRG2qhWf9G22MfZw4aEzYzk6YkndgKtMxgTbwtfqTP",
  parseServerUrl : "https://angry-thompson.212-227-192-65.plesk.page/tracey/parse",
  testServerUrl: "https://angry-thompson.212-227-192-65.plesk.page/tracey/parse",
  // parseServerUrl: "http://localhost:1337/parse",
  // testServerUrl : "http://localhost:1337/parse",

  // parseServerUrl: "https://pag-tracey-nor.euve268526.serverprofi24.de/parse",
  // testServerUrl : "https://pag-tracey-nor.euve268526.serverprofi24.de/parse",
  testAppID: "Jr4BEttGbur54BsBJ6fzpPjRG2qhWf9G22MfZw4aEzYzk6YkndgKtMxgTbwtfqTP",
  scanditKey: 'ASwTUSqCD793ByfWqyvdidE6gco/M4h85loPBvJbTko5W0ubqlMajyFtteUbChHgRm7uvvtrMaAxa3uCm31GlLUHCVMDTIu0qEplI39qpI/1T5rJQDQfqpVZ5dfLW/W0JnrLiZxpp1s2HQkxmnlVS5AWIOD0GvTkjj3NgeI+QiMqaVB3nMZqIFc1KErPIaYOlQys4BIFim3hiL9ivwiz0Nc9f8syPqFZgYlStPrCluQ+WwvLSwDiasjHFqyky+p1Ae7rwR1KRAxgaQsyvIA0K2C4jUgACHRj//OVeSrhUXlDcjAXERc0FXbZ5FcK7As723KEZPEDANyUJaL5GRMNRpQZw02oyKIn3qq+B6T/gb/hFZaPBcHzPir5vUjDCQbN+yLABdlLEbs30/eG3UO2S8FRoNAGbPxHHtNcaFBugoHxLLLzSq191xQmC4GdECmRjlmPH7m2YOLmas+Q8SUlDfHu3a0btjFRg/yhPDlGPNP3clJym6+zcSg/DV6jhsNC69nbiCoMZAf4voTXqm3/wu9xSTEIq61DH3aLgf0l1Lddx9xf+J45CinGhEui7vKWRx+1lRP1Hj8SSx1IA/DWJXsDzPG5taUAYRmEjDZaEuGg9YiGaYFrKw2JHrCaK6vBRCI6MhlyNDTImAz5oUW3c9pQmxuLel7RX3FCW/0eE8LlIMiafP7zc5n0HbZiHSHgJ1sJA7I4RdD6slmuDDcqSZ9VL5CwiMLCWrfJ9iP9dHbp77DedICJ1Szh2ADi0QpNqnu4EOSPFVussg+lQACq8wIxRbVPAYiGxAe7ekkzOYSQCgNV9vi4rIg76g60GqArLnJAAMn4RjQRjpfP0Nvz8w==',
  production: false,
  versionFingerprint: "AnBcAUH3z0LCWzMS7PGlelMHWXI43IAh",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.