export const SUPPLIER_SCAN_REGEX = "(SOLVAY|HEXCEL|Gurit|3M|LOCTITE)"
export const ORDER_SCAN_REGEX = "PartNo_.+\\/IdentNo_\\d{10}$"
export const PALLET_SCAN_REGEX = "G[1-5][A-B][1-6]H[1-5]P[1-3]"
// export const PALLET_SCAN_REGEX = "^G[1-4][A-B][1-5]$"
export const MATERIAL_SCAN_REGEX = "^Ident_\\d{10}\\/Batch_.*\\/RollBucket_.*$"
export const BOX_NUMBER_REGEX = "^B\\d{2}$"
//This constant is for converting ionic pixel unit to device pixel unit
//It is calculated as 76( Value at which the scanner adjusts itself just below the toolbar )/ 56 ( height of tolbar in ionic )
export const SCANNER_PIXEL_FACTOR = 1.35

export enum ScanState {
    PALLET_SCAN = "palletScan",
    MATERIAL_SCAN = "materialScan"
}