import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '@pagmf/security';


@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        @Inject('AuthenticationService') private authenticationService: AuthenticationService
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const authenticated: boolean = await this.authenticationService.isAuthenticated();
        if(!authenticated){
            this.router.navigate(['login']);
        }
        return authenticated;
    }

}
