import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ZebraPrinterService {

  readonly PRINTER_TIMEOUT_TIME = 20000

  async findPrinter(): Promise<string> {
    return new Promise((resolve, reject) => {
      let address = ""
      setTimeout(() => { reject('PRINTER_TIMEOUT') }, this.PRINTER_TIMEOUT_TIME);
      resolve(address);
    })
  }

  async print(address: string, zplCode: string): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      resolve();
    })
  }

}