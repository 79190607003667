import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';

export enum ScannerSymbology {
    CODE39 = "code39",
    DATA_MATRIX = "data-matrix",
    CODE128 = "code128",
    QR = "qr",
    TEXT = "Text"
}

@Injectable()
export class ScannerService {
    settings: ScanSettings;
    picker: BarcodePicker;
    inTransition: boolean = false;

    /* 
    * Subscription that fire an event whenever a code is scanned by the scanner in normal mode
    */
    scannerSubscription: Subject<string> = new Subject();

    constructor(private platform: Platform) {
        if (this.platform.is('mobileweb') || this.platform.is('desktop') ) {
            return
        }

        Scandit.License.setAppKey(environment.scanditKey);

        this.scannerSubscription.subscribe((codeScanned) => {
            console.log("CODE SCANNED - " + codeScanned);
        })
    }


    /**
     * Method to intialize tho setup the scanner for scanning
     */
    setScannerSettings(symbologies: ScannerSymbology[], resize: boolean = true, showManualText: boolean = false,) {

        if (this.platform.is('mobileweb')) {
            return
        }

        const settings = new Scandit.ScanSettings();
        symbologies.forEach(symbology => {
            settings.setSymbologyEnabled(symbology);
        });
        settings.codeRejectionEnabled = true;
        settings.codeDuplicateFilter = 2000;

        this.settings = settings;

        if (this.picker) {
            this.picker.applyScanSettings(settings)
        } else {
            this.picker = new Scandit.BarcodePicker(settings);
        }

        this.picker.continuousMode = true;
        if(resize) {
            const newConstraints = new Scandit.Constraints();
            newConstraints.topMargin = '76';
            newConstraints.rightMargin = '0';
            newConstraints.bottomMargin = '0';
            newConstraints.leftMargin = '0';
    
            this.picker.setConstraints(newConstraints, newConstraints, 0);
        }

        this.picker.getOverlayView().setTextRecognitionSwitchVisible(false)

        this.picker.show({
            didScan: (scanSession) => {
                this.scannerSubscription.next(scanSession.newlyRecognizedCodes[0].data);
            }, didManualSearch: (result) => {
                this.scannerSubscription.next(result);
            }
        });
    }

    /**
     * Method to intialize tho setup the scanner for scanning
     */
    setTextScanSettings(regexString: string) {
        console.log("Scanner - setTextScanSettings");
        if (this.platform.is('mobileweb')) {
            return
        }

        this.settings = new Scandit.ScanSettings();

        const textRecognitionSettings = new Scandit.TextRecognitionSettings();
        textRecognitionSettings.regex = regexString;
        this.settings.textRecognition = textRecognitionSettings;
        this.settings.codeDuplicateFilter = 2000;

        this.settings.recognitionMode = 1;
        this.settings.activeScanningAreaPortrait = new Scandit.Rect(0.1, 0.3, 0.9, 0.7);

        this.picker = new Scandit.BarcodePicker(this.settings);
        this.picker.continuousMode = true;

        const newConstraints = new Scandit.Constraints();
        newConstraints.topMargin = '250';
        newConstraints.rightMargin = '0';
        newConstraints.bottomMargin = '0';
        newConstraints.leftMargin = '0';

        this.picker.setConstraints(newConstraints, newConstraints, 0);
        this.picker.getOverlayView().setViewfinderDimension(0.9, 0.4, 0.9, 0.4);

        this.picker.show({
            didRecognizeText: (recognizedText) => {
                this.scannerSubscription.next(recognizedText.text);
            }, didScan: (scanSession) => {
                this.scannerSubscription.next(scanSession.newlyRecognizedCodes[0].data);
            }, didManualSearch: (result) => {
                this.scannerSubscription.next(result);
            }
        });

    }


    setArScanSetting(symbologies: ScannerSymbology[], showManualText: boolean = false) {
        console.log("Scanner - setArScanSetting");

        if (this.platform.is('mobileweb')) {
            return
        }

        const settings = new Scandit.ScanSettings();
        symbologies.forEach(symbology => {
            settings.setSymbologyEnabled(symbology);
        });
        settings.codeRejectionEnabled = true;
        settings.codeDuplicateFilter = 2000;
        settings.matrixScanEnabled = true;
        settings.maxNumberOfCodesPerFrame = 10;

        this.settings = settings;

        if (this.picker) {
            this.picker.applyScanSettings(settings)
        } else {
            this.picker = new Scandit.BarcodePicker(settings);
        }

        this.picker.continuousMode = true;

        const newConstraints = new Scandit.Constraints();
        newConstraints.topMargin = '76';
        newConstraints.rightMargin = '0';
        newConstraints.bottomMargin = '0';
        newConstraints.leftMargin = '0';

        this.picker.setConstraints(newConstraints, newConstraints, 0);

        this.picker.getOverlayView().setTextRecognitionSwitchVisible(false)
        this.picker.getOverlayView().setGuiStyle(Scandit.ScanOverlay.GuiStyle.MATRIXSCAN);
        // this.picker.getOverlayView().setMatrixScanHighlightingColor(Scandit.ScanOverlay.MatrixScanState.RECOGNIZED, "00FF00")
        this.picker.getOverlayView().setMatrixScanHighlightingColor(Scandit.ScanOverlay.MatrixScanState.REJECTED, "FF0000")


    }


    changeScannerSymbology(symbology: ScannerSymbology) {
        console.log("Scanner - changeScannerSymbology");
        if (this.platform.is('mobileweb')) {
            return
        }

        const settings = new Scandit.ScanSettings();
        settings.setSymbologyEnabled(symbology);
        settings.codeRejectionEnabled = true;

        this.settings = settings;
        this.picker.applyScanSettings(settings)
    }

    setTextRecognitionSetting(regexString: string) {
        console.log("Scanner - setTextRecognitionSetting");
        if (this.platform.is('mobileweb')) {
            return
        }

        const textRecognitionSettings = new Scandit.TextRecognitionSettings();
        textRecognitionSettings.regex = regexString;
        this.settings.textRecognition = textRecognitionSettings;
        this.settings.recognitionMode = 1;
        this.picker.applyScanSettings(this.settings);
    }

    setConstraints(topMargin: string, rightMargin: string, bottomMargin: string, leftMargin: string) {
        if (this.platform.is('mobileweb')) {
            return
        }

        const newConstraints = new Scandit.Constraints();
        newConstraints.topMargin = topMargin;
        newConstraints.rightMargin = rightMargin;
        newConstraints.bottomMargin = bottomMargin;
        newConstraints.leftMargin = leftMargin;

        this.picker.setConstraints(newConstraints, newConstraints, 0);
    }

    /**
     * Method to start the scanner
     */
    startScanning() {
        console.log("Scanner - startScanning");
        if (this.platform.is('mobileweb')) {
            return
        }
        this.picker.startScanning();
    }

    /**
     * Method to pause teh scanning
     */
    pauseScanning() {
        console.log("Scanner - pauseScanning");
        if (this.platform.is('mobileweb')) {
            return
        }
        this.picker.pauseScanning();
    }

    /**
     * Method to resume the scanning
     */
    resumeScanning() {
        console.log("Scanner - resumeScanning");
        if (this.platform.is('mobileweb')) {
            return
        }
        this.picker.resumeScanning();
    }

    /**
     * Method to exit the scanner
     */
    async exitScanning() {
        console.log("Scanner - exitScanning");
        if (this.platform.is('mobileweb')) {
            return
        }
        try {
            await this.picker.cancel();
          } catch (error) {
            console.log(error);
          }

    }

}