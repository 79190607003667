import { Component, OnInit, NgZone, Input } from '@angular/core';
import { ModalController, AlertOptions } from '@ionic/angular';
import { PrinterService } from '@pagmf/printer';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ZplCodeService, MaterialInfoLabel } from 'src/app/features/common/services/zplCode.service';
import { MaterialType } from 'src/app/features/common/model/material-type.model';
import { Material } from 'src/app/features/common/model/material';
import { AlertService } from '../../services/alert.service';
import { ToastService } from '../../services/toast.service';
import { HttpClient } from '@angular/common/http';
import { Printer, PrintOptions } from '@awesome-cordova-plugins/printer/ngx';
import { ZebraPrinter } from '@ionic-native/zebra-printer';

@Component({
  selector: 'app-print-label-modal',
  templateUrl: './print-label-modal.component.html',
})
export class PrintLabelModalComponent implements OnInit {
  unpairedDevices: Printer[] = [];
  pairedDevices: Printer[] = [];
  scanning = false;
  printers: string[] = [];
  baseIp: string = '192.168.1';
  selectedPrinter: string | null = null;
  connected: boolean;
  selectDevice: boolean = false;
  private readonly ports = [9100, 631, 80];
  @Input() material: Material[];
  @Input() materialType: MaterialType;
  @Input() materialInfoLabel?: MaterialInfoLabel;

  constructor(
    private modalController: ModalController,
    private alertService: AlertService,
    private toastService: ToastService,
    private printerService: PrinterService,
    private zone: NgZone,
    private loadingController: LoadingController,
    private translateService: TranslateService,
    private zplCodeService: ZplCodeService,
    private http: HttpClient,
    private printer: Printer
  ) { }

  async ngOnInit() {
    window['cordova'].plugins['printer'].print("Hello\nWorld!");
  }
  printCheck() {

    this.printer.isAvailable().then((onSuccess: any) => {
      let content = '<!DOCTYPE html><html><head><title>Meine Seite</title></head><body><h1>Willkommen!</h1></body></html>'
      let options: PrintOptions = {
        name: 'MyDocument',
        duplex: true,
        orientation: "portrait",
        monochrome: true
      };
      this.printer.print(content, options).then((value: any) => {
        console.log('value:', value);
      }, (error) => {
        console.log('eror:', error);
      });
    }, (err) => {
      console.log('err:', err);
    });
  }
  async ionViewDidEnter() {
    if (!await this.printerService.isEnabled()) {
      this.onAlertBluetoothDisabled();
    } else {
      this.tryPrinting();
    }
  }

  async tryPrinting() {
    if (!this.connected) {
      await this.onAlertNotConnected();
    } else {
      await this.print();
    }
  }

  async printZpl(zplCode: string, printerIp: string) {
    const url = `http://${printerIp}/pstprnt`;
    const headers = { 'Content-Type': 'text/plain' };
    try {
      const response = await this.http.post(url, zplCode, { headers, responseType: 'text' }).toPromise();
      console.log('Druckauftrag erfolgreich:', response);
    } catch (error) {
      console.error('Fehler beim Senden des Druckauftrags:', error);
    }
  }

  private getZplCodeForInput(): string[] {
    let codes: string[] = [];
    if (this.materialType && this.material) {
      this.material.forEach(material => codes.push(this.zplCodeService.getZplCode(this.materialType, material, 0)))
    } else if (this.materialInfoLabel) {
      codes.push(this.zplCodeService.getZplCodeForInfo(this.materialInfoLabel));
    }
    return codes;
  }

  async print() {
    await this.showLoader(this.translateService.instant('PRINTING_LABEL'));
    const zplCodes: string[] = this.getZplCodeForInput();
    zplCodes.forEach(code => console.log(code));
    for (let i = 0; i < zplCodes.length; i++) {
      this.printer.pick();
      this.printer.isAvailable().then((onSuccess: any) => {
        let options: PrintOptions = {
          name: 'MyDocument',
          duplex: true,
          orientation: "portrait",
          monochrome: true
        };
        this.printer.print(zplCodes[i], options).then((value: any) => {
          console.log('value:', value);
        }, (error) => {
          console.log('eror:', error);
        });
      }, (err) => {
        console.log('err:', err);
      });

    }
    await this.hideLoader();
    console.log("sending successful");
    this.toastService.showToast(this.translateService.instant('PRINTER_DATA_SEND_SUCCESSFUL'));
    this.onDismiss(true);
  }

  async getPairedList() {
  }

  async discoverUnpaired() {
    this.scanning = true;
    this.printers = await this.discoverPrinters(this.baseIp, 254);

    this.scanning = false;
  }
  selectPrinter(printer: string) {
  }
  async discoverPrinters(baseIp: string, range: number): Promise<string[]> {
    const foundPrinters: string[] = [];
    const requests = [];

    for (let i = 1; i <= range; i++) {
      const ip = `localhost/printers/myprinter`;
      for (const port of this.ports) {
        requests.push(this.checkPort(ip, port).then((isAvailable) => {
          if (isAvailable) {
            foundPrinters.push(ip);
          }
        }));
      }
    }
    await Promise.all(requests);
    console.log(foundPrinters);
    return foundPrinters;
  }

  private checkPort(ip: string, port: number): Promise<boolean> {
    return new Promise((resolve) => {
      const url = `http://${ip}:${port}`;
      this.http.get(url, { responseType: 'text', observe: 'response' })
        .subscribe(
          (response) => resolve(true),
          (error) => resolve(false)
        );
    });
  }

  async connect(device: Printer) {
    await this.showLoader(this.translateService.instant('CONNECTING'));
    async (error) => {
      let message: string;
      if (error.includes('Unable to connect to device')) {
        this.hideLoader();
        message = 'CONNECTING_FAILED';
      } else {
        message = await this.printerService.isEnabled() ? 'CONNECTION_LOST' : 'BLUETOOTH_OFF';
      }
      console.log("connecting failed" + message);
      this.toastService.showToast(this.translateService.instant(message) + ": " + error);
    }
  }

  async disconnect() {
    await this.printerService.disconnect().then(
      (success) => {
        console.log("disconnect successful");
        this.toastService.showToast(this.translateService.instant('DISCONNECT_SUCCESSFUL'));
        this.connected = false;
      },
      (error) => {
        console.log("disconnecting failed");
        this.toastService.showToast(this.translateService.instant('DISCONNECT_FAILED'));
      }
    );
    await this.tryPrinting();
  }

  async enableBluetooth() {
    await this.printerService.enable().then(
      (success) => {
        console.log("bluetooth enabled");
        this.toastService.showToast(this.translateService.instant('BT_ENABLE_SUCCESSFUL'));
        this.tryPrinting();
      },
      (error) => {
        console.log("enabling failed" + error);
        this.toastService.showToast(this.translateService.instant('BT_ENABLE_FAILED') + ": " + error);
        this.onDismiss();
      }
    );
  }

  async hideLoader(): Promise<any> {
    await this.loadingController.dismiss();
  }

  async showLoader(message: string): Promise<any> {
    await this.loadingController.create({
      message: message
    }).then(
      (loader) => loader.present()
    );
  }

  private async onAlertBluetoothDisabled(): Promise<void> {

    const alertHeader = this.translateService.instant('BT_ENABLE_QUESTION');
    const alertMessage = this.translateService.instant('BT_ENABLE_MESSAGE');
    const cancelButton = this.translateService.instant('CANCEL');
    const enableButton = this.translateService.instant('ENABLE');

    const alertOptions: AlertOptions = {
      header: alertHeader,
      message: alertMessage,
      buttons: [{
        text: cancelButton,
        role: 'cancel',
        handler: () => {
          this.onDismiss();
        }
      },
      {
        text: enableButton,
        handler: async () => {
          this.enableBluetooth();
        }
      }]
    };
    await this.alertService.showAlert(alertOptions);
  }

  private async onAlertNotConnected() {

    const alertHeader = this.translateService.instant('CONNECTING_QUESTION');
    const alertMessage = this.translateService.instant('CONNECTING_MESSAGE');
    const cancelButton = this.translateService.instant('CANCEL');
    const selectButton = this.translateService.instant('SELECT');

    const alertOptions: AlertOptions = {
      header: alertHeader,
      message: alertMessage,
      buttons: [{
        text: cancelButton,
        role: 'cancel',
        handler: () => {
          this.onDismiss();
        }
      },
      {
        text: selectButton,
        handler: async () => {
          await this.getPairedList();
          this.selectDevice = true;
        }
      }]
    };
    await this.alertService.showAlert(alertOptions);
  }

  onDismiss(result: boolean = false) {
    this.print();
    this.modalController.dismiss(result);
  }
}
