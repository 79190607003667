import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AlertOptions } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageConstants } from '../model/local-storage.constants';
import { AlertService } from './alert.service';

enum ThemeOptions {
  DARK = "dark",
  LIGHT = "light"
}

interface Theme {
  name: ThemeOptions;
}

@Injectable({
  providedIn: 'root'
})
export class ThemeSwitcherService {

  private themes: Theme[] = [];
  private currentTheme: number = 0;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private alertService: AlertService,
    private translate: TranslateService
  ) {

    this.themes = [
      {
        name: ThemeOptions.LIGHT,
      },
      {
        name: ThemeOptions.DARK,
      }
    ];

    let theme = localStorage.getItem(LocalStorageConstants.APP_THEME);
    if (theme) {
      this.setTheme(theme)
    } else {
      this.setTheme(ThemeOptions.LIGHT)
    }
  }

  cycleTheme(): void {
    if (this.themes.length > this.currentTheme + 1) {
      this.currentTheme++;
    } else {
      this.currentTheme = 0;
    }
    this.setTheme(this.themes[this.currentTheme].name);
  }

  switchTheme() {
    let theme = localStorage.getItem(LocalStorageConstants.APP_THEME);
    if (theme == ThemeOptions.DARK) {
      this.setTheme(ThemeOptions.LIGHT)
    }
    if (theme == ThemeOptions.LIGHT) {
      this.setTheme(ThemeOptions.DARK)
    }
  }

  setTheme(name: string): void {
    localStorage.setItem(LocalStorageConstants.APP_THEME, name);

    this.document.documentElement.classList.toggle('dark', name === ThemeOptions.DARK);
  }

  async showAlert(): Promise<void> {
    const currentTheme = localStorage.getItem(LocalStorageConstants.APP_THEME);

    const alertOptions: AlertOptions = {
      header: this.translate.instant('THEME.CHOOSE_THEME'),
      inputs: [
        {
          type: 'radio',
          label: this.translate.instant('THEME.LIGHT'),
          value: 'light',
          checked: currentTheme === ThemeOptions.LIGHT
        },
        {
          type: 'radio',
          label: this.translate.instant('THEME.DARK'),
          value: 'dark',
          checked: currentTheme === ThemeOptions.DARK
        }
      ],
      buttons: [
        {
          text: this.translate.instant('CANCEL')
        },
        {
          text: this.translate.instant('CONFIRM'),
          handler: theme => {
            this.setTheme(theme);
          }
        }
      ],
      cssClass: "app-alert"
    };
    await this.alertService.showAlert(alertOptions);
  }
}
